import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const DeleteDocumentModal = ({
	visible,
	onCancel,
	onCreate
}: {
	visible: any;
	onCancel: any;
	onCreate: any;
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			className="gb-modal-40-padding"
			okButtonProps={{
				className: "button-primari-delete-gibobs",
				style: {
					width: '43%',
					height: 48,
					
				}
			}}
			cancelButtonProps={{
				className: "button-secundary-gibobs",
				style: { width: '43%', height: 48}
			}}
			okText={t('docs.deleteButtonNew')}
			cancelText={t('offers.cancel')}
			open={visible}
			title={t('docs.deleteDocNew')}
			width={480}
			onOk={onCreate}
			onCancel={onCancel}
			centered>
			<div style={{ marginTop: 24 }}>{t('docs.deleteDocTextNew')}</div>
		</Modal>
	);
};

export default DeleteDocumentModal;
