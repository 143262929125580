import React, { useState, useEffect } from 'react';
import {
	Typography,
	Steps,
	Radio,
	Row,
	Col,
	Upload,
	Button,
	notification,
	Modal
} from 'antd';

import { useSelector } from 'react-redux';
import axios from 'axios';
import env from '../../../environment';
import { useTranslation } from 'react-i18next';
import { refreshData } from '../../../store/auth/actions';
import {
	CheckOutlined,
	DeleteOutlined,
	DownloadOutlined,
	FilePdfOutlined,
	PlusOutlined
} from '@ant-design/icons';
import _ from 'lodash';
import { showPay } from '../../../store/data/actions';
import { useDispatch } from 'react-redux';
import CheckOutComponent from '../../ProductsImo/CheckOutComponent';
import moment from 'moment';

const { Text } = Typography;
const { Step } = Steps;
const { confirm } = Modal;

const AppraisalDesktop = ({ dataMortgage, operationId }) => {
	const [existAppraisal, setExistAppraisal] = useState(false);
	const [valuesData, setValuesData] = useState([]);
	const userToken = useSelector((state) => state.auth.token);
	const [valueRadio, setValueRadio] = useState(undefined);
	const [mortgageData, setMortgageData] = useState();
	const [isClientPay, setIsClientPay] = useState(false);
	const { t } = useTranslation();
	const [, setCheckBoxData] = useState(
		dataMortgage !== undefined &&
			dataMortgage.mortgage &&
			dataMortgage.mortgage.signedDocumentation === true
			? true
			: false
	);
	const [saveFileDataField, setSaveFileDataField] = useState(null);
	const [typeFile, setTypeFile] = useState();
	const [, setShowModalDelete] = useState(false);
	const [appraisal, setAppraisal] = useState(undefined);
	const dispatch = useDispatch();
	const showPayBoolean = useSelector((state) => state.data.showPay);

	const [dataClient, setDataClient] = useState('');
	const [, setNumberDocuments] = useState([]);
	const [valuesDataForMortgage, setValuesDataForMortgage] = useState([]);
	const [participants, setParticipants] = useState([]);

	useEffect(() => {
		if (operationId) {
			getAppraisal();
			//setValueRadio("own");
			getMortgageData();
			getClientPay();
			getTokenPay();
			getMortgage();
			getParticipants();
		}
	}, []);

	useEffect(() => {
		getClientPay();
	}, [showPayBoolean]);

	useEffect(() => {
		setExistAppraisal(valuesData.some((value) => value.type === 'appraisal'));
	}, [valuesData]);

	const documentsRequired = [];

	useEffect(() => {
		if (ownerList.length > 0) {
			for (let i = 0; i < valuesDataForMortgage.length; i++) {
				for (let j = 0; j < ownerList.length; j++) {
					if (valuesDataForMortgage[i].participants[ownerList[j]] === 'optional') {
						documentsRequired.push(valuesDataForMortgage[i]);
					}
				}
			}
			setNumberDocuments(documentsRequired);
		}
	}, [valuesDataForMortgage]);

	const getMortgage = () => {
		axios
			.get(`${env.api.url}/v1/documents/request-operation-documentation/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setValuesDataForMortgage(response.data.data.documents);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const getParticipants = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setParticipants(response.data.data);
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const numParticipants = parseInt(_.get(participants, 'mortgage.participants', 0));
	const numGuarantors = parseInt(_.get(participants, 'mortgage.guarantors', 0));

	const ownerList = [];

	for (let i = 1; i <= numParticipants; i++) {
		ownerList.push(`owner${i}`);
	}

	for (let i = 0; i < numGuarantors; i++) {
		ownerList.push(`guarantor${i + 1}`);
	}

	const getTokenPay = () => {
		axios
			.post(
				`${env.api.url}/v1/payments/appraisal/checkout`,
				{
					operationId: operationId
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setDataClient(response.data.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const getMortgageData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setMortgageData(response.data.data.mortgage);
				if (response.data.data.mortgage.reportAppraisal !== undefined) {
					setValueRadio(response.data.data.mortgage.reportAppraisal);
				}
			});
	};

	const getClientPay = () => {
		axios
			.post(
				`${env.api.url}/v1/payments/operation/status`,
				{
					operationId: operationId
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				setIsClientPay(response.data.data.completed);
			});
	};

	const getAppraisal = () => {
		axios
			.get(`${env.api.url}/v1/documents/get-operation-documents/${operationId}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				setValuesData(response.data.data);
				setExistAppraisal(response.data.data.some((value) => value.type === 'appraisal'));
				setAppraisal(response.data.data.find((value) => value.type === 'appraisal'));
			})
			.catch((error) => {
				console.log(error)
			});
	};

	

	const saveDataOnClick = (participant, key, value) => {
		let valueRadio = {
			key: 'operation',
			subkey: 'firstClickToAppraisal',
			value: moment().format('YYYY-MM-DD')
		};

		if (mortgageData.reportAppraisal !== undefined) {
			valueRadio = {
				key: 'operation',
				subkey: 'ultimateClickToAppraisal',
				value: moment().format('YYYY-MM-DD')
			};
		}	
			
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${operationId}`,
				{
					values: [
						{
							key: participant,
							subkey: key,
							value: value
						},
						{
							key: valueRadio.key,
							subkey: valueRadio.subkey,
							value: valueRadio.value
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((response) => {
				getMortgageData();
				refreshData();
				setCheckBoxData(response.data.data.mortgage.signedDocumentation);
			});
	};

	const handleUploadFile = (file) => {
		axios
			.put(
				`${env.api.url}/api/mortgages/attachment`,
				{
					mortgageId: operationId,
					file: file[1].file,
					fileName: file[1].fileName,
					type: typeFile,
					owner: 'owner1'
				},
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then(() => {
				getAppraisal();
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const saveFileData = (file, name) => {
		const documentFile = [saveFileDataField];
		documentFile.push({
			file,
			fileName: name
		});
		setSaveFileDataField(documentFile);
		handleUploadFile(documentFile);
	};
	const uploadProps = {
		beforeUpload: (file) => {
			return new Promise(() => {
				const reader = new FileReader();
				reader.readAsDataURL(file);

				if (file.size < 50000000) {
					reader.onload = () => {
						const base64File = reader.result.split(',')[1];
						saveFileData(base64File, file.name);
					};
				} else {
					notification.error({
						message: 'Problema al subir archivo',
						description:
							'Documento demasiado pesado, debe subir documentos que no superen los 50MB por seguridad.'
					});
				}
			});
		}
	};
	const handleClickPlus = (typeFile) => {
		setTypeFile(typeFile);
	};

	const deleteDocument = () => {
		if (!_.isNil(appraisal.page)) {
		} else {
			axios
				.get(`${env.api.url}/v1/documents/delete-document/${appraisal.id}`, {
					headers: { Authorization: `Bearer ${userToken}` }
				})
				.then(() => {
					setShowModalDelete(false);
					getAppraisal();
				})
				.catch((error) => {
					console.log(error)
				});
		}
	};

	const modalDelete = (documentId) => {
		confirm({
			className: 'ModalCommonConfirm',
			title: 'Borrar documento',
			icon: null,
			content: '¿Seguro que deseas borrar este documento?',
			okText: 'Borrar',
			okType: 'danger',
			cancelText: 'Cancelar',
			onOk() {
				deleteDocument(documentId);
			},
			onCancel() {
				console.log('Cancel');
			}
		});
	};

	const handleFileDownload = (documentId) => {
		axios
			.get(`${env.api.url}/v1/documents/get-document-url/${documentId}?token=${userToken}`, {
				headers: { Authorization: `Bearer ${userToken}` }
			})
			.then((response) => {
				if (response.data.success) {
					window.open(response.data.data.url, '_blank'); //to open new page
				}
			})
			.catch((error) => {
				console.log(error)
			});
	};

	const buttonStyles = () => {
		return (
			<div
				className="gibobs-document-button-overlay"
				style={{
					position: 'absolute',
					width: 104,
					height: 104,
					top: 0,
					left: 0,
					backgroundColor: '#02C3CD',
					lineHeight: 10,
					borderRadius: '4px'
				}}>
				{existAppraisal && !_.isNil(appraisal.page) ? null : (
					<Button
						type="text"
						size="small"
						onClick={() => {
							modalDelete(appraisal.id);
						}}>
						<DeleteOutlined
							style={{
								marginTop: '10px',
								// marginRight: "3px",
								color: '#ffff'
							}}
						/>
					</Button>
				)}
				<Button
					type="text"
					size="small"
					onClick={() => {
						handleFileDownload(appraisal.id);
					}}>
					<DownloadOutlined
						style={{
							marginTop: '10px',
							marginRight: '3px',
							color: '#ffff'
						}}
					/>
				</Button>
			</div>
		);
	};

	const renderSelect = () => {
		return (
			<div style={{ marginTop: 8 }}>
				{_.isNil(mortgageData?.appraiser) && (
					<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
						{t('m_appraisal.sectionTitle').toUpperCase()}
					</Text>
				)}
				{!isClientPay && (
					<Row style={{ marginTop: 16, display: 'flex', width: '100%' }}>
						<Col span={24} style={{ textAlign: 'left', width: '100%' }}>
							<Radio
								value={'own'}
								checked={mortgageData && mortgageData.reportAppraisal === 'own' ? true : false}
								onChange={(e) => {
									saveDataOnClick('mortgage', 'reportAppraisal', e.target.value);
									setValueRadio(e.target.value);
								}}>
								<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
									{t('appraisal.miAppraisal')}
								</Text>
							</Radio>
						</Col>
					</Row>
				)}

				{valueRadio !== 'h1' &&
					!_.isNil(valueRadio) &&
					existAppraisal === false &&
					mortgageData &&
					mortgageData.reportAppraisal !== 'bank' && (
						<div style={{ marginTop: 24 }}>
							<Upload {...uploadProps}>
								<Button
									onClick={() => handleClickPlus('appraisal')}
									style={{
										width: 104,
										height: 104,
										backgroundColor: 'white',
										borderRadius: '4px'
									}}>
									<div
										style={{
											marginBottom: '20px',
											marginTop: '10px',
											marginBottom: 5
										}}>
										<PlusOutlined style={{ color: '#02C3CD', fontSize: 20 }} />
									</div>
									<div
										style={{
											fontSize: '12px',
											whiteSpace: 'break-spaces',
											color: '#2F4858',
											fontWeight: 400
										}}>
										{t('document.attach')}
									</div>
								</Button>
							</Upload>
						</div>
					)}
				{existAppraisal && appraisal && appraisal.type === 'appraisal' && (
					<div style={{ marginTop: 24 }}>
						<Button
							className="gibobs-document-button"
							style={{
								width: 104,
								height: 104,
								backgroundColor: 'white',
								borderRadius: '4px',
								borderColor: !_.isNil(appraisal.page) ? '#52C41A' : undefined
							}}
							//onClick={() => setShowModalDelete(true)}
						>
							{!_.isNil(appraisal.page) && (
								<div
									style={{
										width: '100%',
										textAlign: 'right',
										marginTop: -4,
										marginLeft: 5
									}}>
									<CheckOutlined
										style={{
											color: '#52C41A'
										}}
									/>
								</div>
							)}
							<div
								style={{
									marginBottom: '10px',
									marginTop: '5px',
									marginLeft: '-5px'
								}}>
								<FilePdfOutlined style={{ fontSize: '20px', color: '#748EA0' }} />
							</div>
							<div
								className="textButton"
								style={{
									width: '72px',
									height: '54px',
									fontSize: '10px',
									whiteSpace: 'normal'
								}}>
								{buttonStyles(appraisal.id)}

								<Text
									style={{
										color: '#748EA0',
										fontSize: 12,
										fontWeight: 400
									}}>
									{' '}
									{t('stages.appraisal')}
								</Text>
							</div>
						</Button>
					</div>
				)}

				{!existAppraisal && (
					<Row style={{ marginTop: !_.isNil(mortgageData?.appraiser) ? 20 : 8 }}>
						<Col span={24}>
							{isClientPay === false && (
								<Radio
									value={'h1'}
									checked={mortgageData && mortgageData.reportAppraisal === 'h1' ? true : false}
									onChange={(e) => {
										saveDataOnClick('mortgage', 'reportAppraisal', e.target.value);
										setValueRadio(e.target.value);
									}}>
									<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
										{t('appraisal.gibobs')}
									</Text>
								</Radio>
							)}
							{valueRadio === 'h1' && _.isNil(mortgageData?.appraiser) && (
								<div style={{ marginTop: 16 }}>
									<Text
										style={{
											fontSize: 12,
											fontWeight: 400,
											color: '#748EA0'
										}}>
										{t('appraisal.infoGibobs')}
									</Text>
								</div>
							)}
							{valueRadio === 'h1' && (
								<>
									<div style={{ marginTop: isClientPay ? -8 : 16 }}>
										<Text
											style={{
												fontSize: 12,
												fontWeight: 400,
												color: '#748EA0'
											}}>
											{!_.isNil(mortgageData?.appraiser) && isClientPay === false
												? t('appraisal.availableAppraisal')
												: isClientPay
												? t('m_appraisal.paymentDone')
												: undefined}
										</Text>
									</div>
									<div style={{ marginTop: 16 }}>
										<div style={{ display: 'flex' }}>
											<Text
												style={{
													color: '#2F4858',
													fontWeight: 400,
													fontSize: 12
												}}>
												{t('appraisal.appraiser')}
											</Text>
											&nbsp;
											<Text
												style={{
													color: '#02C3CD',
													fontWeight: 400,
													fontSize: 12
												}}>
												{!_.isNil(mortgageData?.appraiser)
													? mortgageData?.appraiser
													: t('appraisal.confirm')}
											</Text>
										</div>
										<div style={{ display: 'flex', marginBottom: 20 }}>
											<Text
												strong
												style={{
													color: '#2F4858',
													fontWeight: 600,
													fontSize: 12
												}}>
												{t('appraisal.price')}
											</Text>
											&nbsp;
											<Text
												style={{
													color: '#02C3CD',
													fontWeight: 600,
													fontSize: 12
												}}>
												{!_.isNil(mortgageData?.appraisalAmount) && !isClientPay
													? mortgageData?.appraisalAmount + '€'
													: isClientPay
													? 0 + '€'
													: t('appraisal.confirm')}
											</Text>
										</div>
									</div>
									{valueRadio === 'h1' && !isClientPay && (
										<Button
											className="ButtonPagar"
											onClick={() => payOk()}
											disabled={!_.isNil(dataMortgage.mortgage.appraisalAmount) ? false : true}
											style={{
												width: '72px',
												height: '24px',
												borderRadius: '4px',
												textAlign: 'center',
												backgroundColor:
													dataMortgage.mortgage.appraiser !== undefined ? '#02C3CD' : '#C0DAEE'
											}}>
											<div
												style={{
													textAlign: 'center',
													marginTop: '-5px',
													color: 'white'
												}}>
												{t('appraisal.pay')}
											</div>
										</Button>
									)}
								</>
							)}
						</Col>
					</Row>
				)}
			</div>
		);
	};

	const renderDescOption2 = () => {
		if (appraisal && mortgageData?.reportAppraisal !== 'h1') {
			if (!_.isNil(appraisal.page)) {
				return (
					<Text
						style={{
							fontSize: 14,
							fontWeight: 400,
							color: '#748EA0'
						}}>
						{t('m_appraisal.ownPendingDescription')}
					</Text>
				);
			} else {
				return undefined;
			}
		} else {
			if (appraisal && mortgageData?.reportAppraisal === 'h1') {
				return (
					<Text
						style={{
							fontSize: 12,
							fontWeight: 400,
							color: '#748EA0'
						}}>
						{t('appraisal.availableFile')}
					</Text>
				);
			}
		}
	};

	const payOk = () => {
		dispatch(showPay(true));
	};

	return (
		<div style={{ padding: showPayBoolean ? undefined : 16 }}>
			{!showPayBoolean && (
				<div>
					<Steps
						direction="vertical"
						size="small"
						status="error"
						current={appraisal && !_.isNil(appraisal.page) ? 1 : 0}
						className="StepsMobileAppraisal">
						<Step
							style={{ color: '#b3b3b3' }}
							title={
								<Text
									style={{
										fontSize: 12,
										fontWeight: 400,
										color: '#02C3CD'
									}}>
									{t('appraisal.infoAppraisal')}
								</Text>
							}
							description={renderSelect()}></Step>
						<Step
							style={{ color: '#b3b3b3' }}
							title={
								<Text
									style={{
										fontSize: 12,
										fontWeight: 400,
										color: appraisal && !_.isNil(appraisal.page) ? '#02C3CD' : '#C0DAEE'
									}}>
									{t('appraisal.bank')}
								</Text>
							}
							description={renderDescOption2()}></Step>
					</Steps>
				</div>
			)}

			{showPayBoolean && dataMortgage && (
				<div style={{ marginBottom: 200 }}>
					<CheckOutComponent
						amount={dataMortgage?.mortgage?.appraisalAmount}
						showPayBoolean={showPayBoolean}
						mortgageId={operationId}
						clientSecretToken={dataClient?.paymentSeat?.session?.client_secret}
						dataClient={dataClient}
					/>
				</div>
			)}
		</div>
	);
};

export default AppraisalDesktop;
