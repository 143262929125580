import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { UserOutlined, WhatsAppOutlined, MailOutlined, } from "@ant-design/icons";
import { Avatar, Button, Modal, Divider, } from "antd";
import { isMobileOnly } from "react-device-detect";
import axios from "axios";
import { useSelector } from "react-redux";
import env from "../../../../environment";

const AnalystIntroModal = ({ operationId, changesStage }) => {
    const { t } = useTranslation();
    const [infoAgents, setInfoAgents] = useState();
    const image = infoAgents?.analyst?.avatar?.link
    const [showModalAvatar, setShowModalAvatar] = useState(false);
    const [operationData, setOperationData] = useState(undefined);
    const userToken = useSelector((state) => state.auth.token);

    useEffect(() => {
        getAgent();
        getOperationData();
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!operationData?.process?.showModalAnalystIntro) {
                setShowModalAvatar(true);
            }
        }, 15000);

        return () => clearTimeout(timeoutId);
    }, [operationData]);

    const getAgent = () => {
        axios
            .get(`${env.api.url}/v1/mortgages/agents/${operationId}`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            .then((response) => {
                setInfoAgents(response.data.data);
            });
    };

    const getOperationData = async () => {
        await axios
            .get(`${env.api.url}/v1/mortgages/data/get/${operationId}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then((response) => {
                setOperationData(response.data.data);

            });
    };

    const closeModal = () => {
        if (!operationData?.process?.showModalAnalystIntro) {
            axios
                .post(
                    `${env.api.url}/v1/mortgages/data/update/${operationId}`,
                    {
                        key: 'showModalAnalystIntro',
                        participant: 'process',
                        value: true
                    },
                    {
                        headers: { Authorization: `Bearer ${userToken}` }
                    }
                )
                .then((response) => {
                    if (response.data.success) {
                        setShowModalAvatar(false);
                      
                    }

                });
        }
        setShowModalAvatar(false);
        
    };

    const continueProcess = () => {
        setShowModalAvatar(false);
        changesStage('process', 'basicCompleted', true);
    };

    return (
        <>
            {infoAgents &&
                <>
                    <div style={{
                        position: isMobileOnly && 'absolute',
                        bottom: isMobileOnly && 150,
                        right: isMobileOnly && 0
                    }}>
                        <div
                            style={{
                                float: 'right',
                                marginRight: 32,
                                cursor: 'pointer',
                                position: 'relative',
                                width: 64,
                                height: 64,
                                overflow: 'hidden',
                                border: '2px solid #02C3CD',
                                borderRadius: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            onClick={() => setShowModalAvatar(!showModalAvatar)}>
                            {image !== undefined && image !== null ? (
                                <img
                                    src={image}
                                    alt="imageAgent"
                                    width={'100%'}
                                    height={'100%'} />
                            ) : (
                                <Avatar size={64} icon={<UserOutlined />} style={{ width: '100%' }} />
                            )}
                            <div style={{
                                position: 'absolute',
                                top: 35,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: '100%',
                                backgroundColor: "#02C3CD ",
                                textAlign: 'center'
                            }}>
                                <WhatsAppOutlined style={{ fontSize: 10, color: '#fff', marginRight: 8, marginTop: 9 }} />
                                <MailOutlined style={{ fontSize: 10, color: '#fff', marginTop: 9 }} />
                            </div>
                        </div>
                    </div>
                    <Modal
                        className="gb-modal-32-padding"
                        closable={true}
                        style={{ maxWidth: isMobileOnly ? 'calc(100% - 32px)' : 480 }}
                        centered
                        width='auto'
                        onCancel={closeModal}
                        open={showModalAvatar}
                        title={t('viability.greetingModal', { name: infoAgents.analyst.name })}
                        footer={
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '16px 32px', width: '100%' }}>
                                <Button
                                    onClick={continueProcess}
                                    type='primary'
                                    style={{ width: '100%', height: 40 }}>
                                    {t('viability.continue')}
                                </Button>
                            </div>
                        }>
                        <div style={{ display: 'flex', marginTop: 16, gap: 16 }}>
                            {!isMobileOnly &&
                                <div style={{ minWidth: 64 }}>
                                    {image !== undefined && image !== null ? (
                                        <img
                                            src={image}
                                            alt="imageAgent"
                                            width={64}
                                            height={64}
                                            style={{ borderRadius: "50%", border: '2px solid #02C3CD' }}
                                        />

                                    ) : (
                                        <Avatar size={64} icon={<UserOutlined />} style={{ border: '2px solid #02C3CD' }} />
                                    )}

                                </div>
                            }
                            <div style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}
                                dangerouslySetInnerHTML={{
                                    __html: t('viability.contactDetails', { phone: infoAgents.analyst.phone })
                                }}
                            />
                        </div>
                        <Divider style={{ borderColor: '#C0DAEE', marginTop: 16, marginBottom: 16 }} />
                        <div style={{ fontSize: 14, fontWeight: 400, color: '#2F4858' }}
                            dangerouslySetInnerHTML={{
                                __html: t('viability.benefits')
                            }}
                        />
                    </Modal>
                </>
            }
        </>
    );
};

export default AnalystIntroModal;
