import React, { useState, useEffect } from 'react';
import {
	Typography,
	Card,
	Layout,
	Form,
	Row,
	Col,
	Select,
	Input,
	Button,
	Modal,
	Checkbox
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import env from '../../environment';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { notificationGibobs } from './reusableItems';
import DownOutlinedMobile from '../../assets/icons/DownOutlinedMobile.svg';
import { InputNumber, ProvinceSelector } from 'afrodita';

const { Text } = Typography;
const { Option } = Select;

function SubrogationRequest() {
	const navigate = useNavigate();;
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const userToken = useSelector((state) => state.auth.token);

	const [interestedGreenMortgage, setInterestedGreenMortgage] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [ecoHouseValue, setEcoHouseValue] = useState();
	const [pageNumber, setPageNumber] = useState(1);
	const [values, setValues] = useState(['solar', 'aerothermal', 'enclosure']);
	const [loading, setLoading] = useState(false);
	const [valuesForm1, setValuesForm1] = useState();
	const [valuesForm2, setValuesForm2] = useState();
	const [nameBanks, setNameBanks] = useState(null);
	const [, forceUpdate] = useState({});

	useEffect(() => {
		axios
			.get(`${env.api.url}/v1/banks/get-banks`, {
				headers: {
					Authorization: `Bearer ${userToken}`
				}
			})
			.then((response) => {
				setNameBanks(response.data.data);
			});
	}, []);

	useEffect(() => {
		forceUpdate({});
	}, []);

	const handleCancel = () => {
		setIsModalVisible(false);
		navigate('/sales-tools/mortgages');
	};

	const handleOk = () => {
		setIsModalVisible(false);

		navigate('/sales-tools/follow-up');
	};

	const onFinish = (values) => {
		setLoading(true);

		values.housingDelivery = values.housingDelivery;

		var objNuevo = new Object();

		if (values.housingDelivery !== undefined) {
			objNuevo.housingDelivery = values.housingDelivery;
		}
		if (values.description !== undefined) {
			objNuevo.description = values.description;
		}
		if (values.mortgageCurrentBank !== undefined) {
			objNuevo.bankId = values.mortgageCurrentBank;
		}

		if (values.interestedGreenMortgage) {
			objNuevo.address = values.address;
			objNuevo.ecoHouseType = values.ecoHouseType;
			objNuevo.floorNumber = values.floorNumber;
			objNuevo.floorSurface = values.floorSurface;
			objNuevo.rooms = values.rooms;
			objNuevo.ecoProjects = values.ecoHouseType === 'piso' ? ['enclosure'] : values.ecoProjects;
		}

		objNuevo.name = values.name;
		objNuevo.surname = values.surname;
		objNuevo.email = values.email;
		objNuevo.mobilePhone = values.mobilePhone;
		objNuevo.resident = values.resident;
		objNuevo.language = values.language;
		objNuevo.province = values.province;
		objNuevo.houseType = values.houseType;
		objNuevo.interestedGreenMortgage = values.interestedGreenMortgage;
		objNuevo.pendingAmount =
			typeof values.amount === 'string' ? parseInt(values.amount.replace('.', '')) : values.amount;
		objNuevo.type = 'subrogation';

		axios
			.post(
				`${env.api.url}/v1/clients/is-recent-client`,
				{ email: values.email },
				{
					headers: { Authorization: `Bearer ${userToken}` }
				}
			)
			.then((responde) => {
				if (responde.data.data) {
					setIsModalVisible(true);

					axios
						.post(`${env.api.url}/api/clients/request-permission`, objNuevo, {
							headers: { Authorization: `Bearer ${userToken}` }
						})
						.then(() => {
							setLoading(false);
						})
						.catch(() => {
							notificationGibobs(
								'error',
								t('m_full.errorTitle'),
								'Hay un problema al insertar su solicitud, pongase en contacto con el departamento de Atención al Cliente.'
							);
						});
				} else {
					axios
						.post(`${env.api.url}/api/clients/request-permission`, objNuevo, {
							headers: { Authorization: `Bearer ${userToken}` }
						})
						.then(() => {
							Modal.success({
								title: t('form.applyFor'),
								content: (
									<div style={{ marginLeft: -30 }}>
										<p>{t('form.text1')}</p>
										<p>{t('form.text2')}</p>
										<p>{t('form.text3')}</p>
									</div>
								),
								onOk: () => {
									navigate('/sales-tools/follow-up');
								},
								style: { padding: 32 },
								className: 'modal-apply-for-inmo',
								icon: <CheckCircleOutlined />
							});
							setLoading(false);
						})
						.catch(() => {
							notificationGibobs(
								'error',
								t('m_full.errorTitle'),
								'Hay un problema al insertar su solicitud, pongase en contacto con el departamento de Atención al Cliente.'
							);
						});
				}
			});
	};

	const validateMessages = {
		required: '${label}' + t('form.formRequired'),
		types: {
			email: t('form.emailValidated'),
			number: '${label} is not a valid number!'
		},
		number: {
			range: t('form.maxBudgetNewRequestValidated') + '${min}'
		}
	};

	function onChange(checkedValues) {
		console.log('checked = ', checkedValues);
	}

	const onFinishForm1 = (values) => {
		setPageNumber(2);
		setValuesForm1(values);
	};
	const onFinishForm2 = (values) => {
		if (interestedGreenMortgage) {
			setPageNumber(3);
			setValuesForm2(values);
		}
		if (!interestedGreenMortgage) {
			const newValues = { ...values, ...valuesForm1 };
			onFinish(newValues);
		}
	};
	const onFinishForm3 = (values) => {
		console.log(values);
		const newValues = { ...values, ...valuesForm2, ...valuesForm1 };
		onFinish(newValues);
	};
	const openNotificationWithIcon = () => {
		notificationGibobs('error', t('m_full.errorTitle'), t('form.incompleteInformation'));
	};

	const optionsWithDisabled = [
		{
			label: t('form.solarPanels'),
			value: 'solar',
			disabled: ecoHouseValue !== 'piso' ? false : true
		},
		{
			value: 'aerothermal',
			label: t('form.aerothermy'),
			disabled: ecoHouseValue !== 'piso' ? false : true
		},
		{
			value: 'enclosure',
			label: t('form.closed')
		}
	];

	const formMortgage = (
		<>
			{pageNumber === 1 && (
				<Form
					className={isMobile ? 'gb-form-profile-headlines-mobile' : 'gb-form-common-new-style'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					form={form}
					name="nest-messages"
					onFinish={onFinishForm1}
					layout="vertical"
					validateMessages={validateMessages}
					initialValues={{ resident: 'yes', language: 'es' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: isMobile && '16px',
							alignItems: 'center',
							marginBottom: !isMobile ? '40px' : '8px'
						}}>
						<div className="typography-titles">{t('operationTypes.subrogation')}</div>
						<div className="typography-blue-text">{t('m_products.basicPersonalDataTitle')}</div>
					</div>
					<Row
						gutter={24}
						style={{
							marginLeft: 'auto',
							marginRight: 'auto'
							// marginBottom: isMobile && "150px",
						}}>
						<Col lg={6} xs={24}>
							<Form.Item
								name="name"
								label={t('form.name')}
								rules={[
									{
										required: true
									}
								]}>
								<Input
									placeholder={t('form.name')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="surname"
								label={t('form.surname')}
								rules={[
									{
										required: true
									}
								]}>
								<Input
									placeholder={t('form.surname')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								name="email"
								label={t('form.email')}
								rules={[
									{
										required: true,
										type: 'email'
									}
								]}>
								<Input
									placeholder={t('form.email')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="mobilePhone"
								label={t('form.phone')}
								rules={[
									{
										required: true
									}
								]}>
								<Input
									placeholder={t('form.phone')}
									style={{
										width: '100%'
									}}
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="resident"
								label={t('form.residentSpain')}
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									placeholder={t('form.residentSpain')}
									style={{
										width: '100%'
									}}>
									<Option key="yes">{t('form.yes')}</Option>
									<Option key="no">{t('form.no')}</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="language"
								label={t('form.language')}
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									placeholder={t('form.language')}
									style={{
										width: '100%'
									}}>
									<Option key="es">{t('form.es')}</Option>
									<Option key="en">{t('form.en')}</Option>
									{/* <Option key="cat">{t("form.cat")}</Option> */}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					{isMobile && (
						<Card
							className="cardButtonStyle"
							style={{ left: 0, marginBottom: -2, backgroundColor: '#FAFEFE' }}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: 16,
									width: '100%',
									height: 48,
									fontWeight: 600
								}}
								htmlType="submit"
								loading={loading}>
								{t('form.next')}
							</Button>
						</Card>
					)}
					{!isMobile && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 60
							}}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600
								}}
								htmlType="submit">
								{t('form.next')}
							</Button>
						</div>
					)}
				</Form>
			)}
			{pageNumber === 2 && (
				<Form
					className={isMobile ? 'gb-form-profile-headlines-mobile' : 'gb-form-common-new-style'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					form={form}
					name="nest-messages"
					onFinish={onFinishForm2}
					layout="vertical"
					validateMessages={validateMessages}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: isMobile && '16px',
							alignItems: 'center',
							marginBottom: !isMobile ? '40px' : '8px'
						}}>
						<div className="typography-titles">{t('operationTypes.subrogation')}</div>
						<div className="typography-blue-text">{t('form.propertyData')}</div>
					</div>
					<Row
						gutter={24}
						style={{
							marginLeft: 'auto',
							marginRight: 'auto'
							// marginBottom: isMobile && "150px",
						}}>
						<Col lg={6} xs={24}>
							<Form.Item label={t('form.mortgageCurrentBanck')} name="mortgageCurrentBank">
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									style={{ width: '100%' }}
									showSearch
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									placeholder={t('form.select')}>
									{nameBanks
										? nameBanks.map((nameBanks) => {
												return (
													<Option key={nameBanks.id} value={nameBanks.id}>
														{nameBanks.name}
													</Option>
												);
										  })
										: null}
								</Select>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								label={t('subrogation.capitalPending')}
								name="amount"
								rules={[
									{
										required: true
									}
								]}>
								<InputNumber
									style={{
										borderRadius: '4px',
										width: '100%'
									}}
									placeholder={t('subrogation.capitalPending')}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.province')}
								name="province"
								rules={[
									{
										required: true
									}
								]}>
								<ProvinceSelector placeholder={t('form.province')} style={{ width: '100%' }} />
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.houseType')}
								name="houseType"
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									placeholder={t('form.houseType')}
									style={{ width: '100%' }}
									rules={[
										{
											required: true
										}
									]}>
									<Option value="main">{t('form.main')}</Option>
									<Option value="second">{t('form.second')}</Option>
									<Option value="investor">{t('form.investor')}</Option>
									<Option value="both">{t('form.both')}</Option>
								</Select>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="interestedGreenMortgage"
								label={t('form.clientMortgageGreen')}
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									placeholder={t('form.select')}
									style={{ width: '100%' }}
									rules={[
										{
											required: true
										}
									]}
									onChange={(value) => {
										setInterestedGreenMortgage(value);
									}}>
									<Option value={true}>{t('form.yes')}</Option>
									<Option value={false}>{t('form.no')}</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label={t('form.descriptionInmo')} name="description">
								<TextArea
									style={{ width: '100%', minHeight: 40 }}
									placeholder={t('form.writeYourComments')}
								/>
							</Form.Item>
						</Col>
					</Row>

					{isMobile && (
						<Card
							className="cardButtonStyle"
							style={{ left: 0, marginBottom: -2, backgroundColor: '#FAFEFE' }}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-secundary-gibobs-inmo"
								type="dashed"
								style={{
									fontSize: '14px',
									width: '48%',
									height: 48,
									fontWeight: 600,
									marginRight: '10px'
								}}
								onClick={() => setPageNumber(1)}>
								{t('form.previous')}
							</Button>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: 16,
									width: '48%',
									height: 48,
									fontWeight: 600
								}}
								htmlType="submit"
								loading={loading}
								disabled={
									form.getFieldValue().length > 12 ||
									!!form.getFieldsError().filter(({ errors }) => errors.length).length
								}>
								{t('form.next')}
							</Button>
						</Card>
					)}
					{!isMobile && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 60
							}}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-secundary-gibobs-inmo"
								type="dashed"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600,
									marginRight: '10px'
								}}
								onClick={() => setPageNumber(1)}>
								{t('form.previous')}
							</Button>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600
								}}
								loading={loading}
								htmlType="submit"
								disabled={
									form.getFieldValue().length > 12 ||
									!!form.getFieldsError().filter(({ errors }) => errors.length).length
								}>
								{t('form.next')}
							</Button>
						</div>
					)}
				</Form>
			)}
			{interestedGreenMortgage && pageNumber === 3 && (
				<Form
					className={isMobile ? 'gb-form-profile-headlines-mobile' : 'gb-form-common-new-style'}
					onFinishFailed={openNotificationWithIcon}
					scrollToFirstError={true}
					form={form}
					name="nest-messages"
					onFinish={onFinishForm3}
					layout="vertical"
					validateMessages={validateMessages}
					initialValues={{
						ecoProjects: values
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							padding: isMobile && '16px',
							alignItems: 'center',
							marginBottom: !isMobile ? '40px' : '8px'
						}}>
						<div className="typography-titles">{t('operationTypes.subrogation')}</div>
						<div className="typography-blue-text">
							{isMobile ? t('form.ecostateTitle') : t('m_products.ecostateTitle')}
						</div>
					</div>
					<Row
						gutter={24}
						style={{
							marginLeft: 'auto',
							marginRight: 'auto'
							// marginBottom: isMobile && "150px",
						}}>
						<Col lg={24} xs={24}>
							<Form.Item
								label={
									isMobile ? (
										<div style={{ fontWeight: 600, fontSize: 12 }}>
											{t('form.labelProyectEco').toUpperCase()}
										</div>
									) : (
										t('form.labelProyectEco')
									)
								}
								name="ecoProjects"
								rules={[
									{
										required: true
									}
								]}>
								<Checkbox.Group
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: isMobile && 'column',
										flexWrap: !isMobile && 'wrap',
										justifyContent: !isMobile && 'space-between',
										rowGap: isMobile && 12,
										marginTop: isMobile && 4,
										backgroundColor: '#FFFFFF'
									}}
									options={optionsWithDisabled}
									onChange={onChange}
									defaultValue={['solar']}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								label={t('form.totalFloor')}
								name="floorSurface"
								rules={[
									{
										type: 'number',
										min: 0,
										required: true
									}
								]}>
								<InputNumber
									style={{
										width: '100%'
									}}
									placeholder={t('m2')}
									defaultValue=""
								/>
							</Form.Item>
						</Col>

						<Col lg={6} xs={24}>
							<Form.Item
								name="ecoHouseType"
								label={t('budget.typeHome')}
								rules={[
									{
										required: true
									}
								]}>
								<Select
									suffixIcon={<img src={DownOutlinedMobile} alt="DownOutlinedMobile" />}
									style={{ width: '100%' }}
									placeholder={t('budget.typeHome')}
									onChange={(value) => {
										setValues(['enclosure']);
										setEcoHouseValue(value);
									}}>
									<Option value="individual">{t('budget.individual')}</Option>
									<Option value="adosado">{t('budget.townhouse')}</Option>
									<Option value="pareado">{t('budget.couplet')}</Option>
									<Option value="casaRustica">{t('budget.rusticHouse')}</Option>
									<Option value="piso"> {t('budget.house')}</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								name="floorNumber"
								label={t('budget.Nfloor')}
								rules={[
									{
										required: true
									}
								]}>
								<InputNumber
									style={{
										width: '100%'
									}}
									defaultValue={''}
									placeholder={'0'}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								name="rooms"
								label={t('budget.room')}
								rules={[
									{
										required: true
									}
								]}>
								<InputNumber
									style={{
										width: '100%'
									}}
									defaultValue={''}
									placeholder={'0'}
								/>
							</Form.Item>
						</Col>
						<Col lg={6} xs={24}>
							<Form.Item
								name="address"
								label={t('budget.direction')}
								rules={[
									{
										required: true
									}
								]}>
								<Input
									style={{
										width: '100%'
									}}
									defaultValue={''}
									placeholder={'calle sin numero...'}
								/>
							</Form.Item>
						</Col>
					</Row>

					{isMobile && (
						<Card
							className="cardButtonStyle"
							style={{ left: 0, marginBottom: -2, backgroundColor: '#FAFEFE' }}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-secundary-gibobs-inmo"
								type="dashed"
								style={{
									fontSize: 16,
									width: '48%',
									height: 48,
									fontWeight: 600,
									marginRight: '10px'
								}}
								onClick={() => setPageNumber(2)}>
								{t('form.previous')}
							</Button>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: 16,
									width: '48%',
									height: 48,
									fontWeight: 600
								}}
								loading={loading}
								htmlType="submit"
								disabled={
									form.getFieldValue().length > 12 ||
									!!form.getFieldsError().filter(({ errors }) => errors.length).length
								}>
								{t('form.next')}
							</Button>
						</Card>
					)}
					{!isMobile && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: 60
							}}>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-secundary-gibobs-inmo"
								type="dashed"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600,
									marginRight: '10px'
								}}
								onClick={() => setPageNumber(2)}>
								{t('form.previous')}
							</Button>
							<Button
								id="gb-mortgage-buy-situation-mobile"
								className="button-primary-gibobs-inmo"
								type="primary"
								style={{
									fontSize: '14px',
									width: 120,
									height: 40,
									fontWeight: 600
								}}
								loading={loading}
								htmlType="submit"
								disabled={
									form.getFieldValue().length > 12 ||
									!!form.getFieldsError().filter(({ errors }) => errors.length).length
								}>
								{t('form.next')}
							</Button>
						</div>
					)}
				</Form>
			)}
		</>
	);

	return (
        (<Layout>
            {isModalVisible && (
				<Modal
					cancelButtonProps={{ className: 'button-secundary-gibobs-inmo' }}
					okButtonProps={{ className: 'button-primary-gibobs-inmo' }}
					open={isModalVisible}
					title={t('form.existCliente')}
					okText={t('form.yes')}
					cancelText={t('form.no')}
					width={674}
					onCancel={handleCancel}
					onOk={handleOk}>
					<Text style={{ fontSize: '12px', height: '102px', width: '600px' }}>
						{t('form.exist')}
					</Text>
				</Modal>
			)}
            {!isMobile && (
				<Card
					className="CardStyleRealeState"
					style={{
						marginLeft: isMobile ? '16px' : '40px',
						width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 80px)',
						marginTop: !isMobile && 40
					}}>
					{formMortgage}
					<div style={{ marginTop: 24 }}>
						<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
							{t('inmo.footerText')}
						</Text>
					</div>
				</Card>
			)}
			{isMobile && (
				<>
					{' '}
					<div className="gb-form-inmo-scroll">
						{formMortgage}
						<div style={{ marginTop: 24, marginLeft: 16 }}>
							<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
								{t('inmo.footerText')}
							</Text>
						</div>
					</div>
				</>
			)}
		</Layout>)
	);
}

export default SubrogationRequest;
