import { Col, Row, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';

const { Text } = Typography;

export const getDocumentInfoByDownlands = (platform: any) => {
	const lang = localStorage.getItem('i18nextLng');

	if (lang === 'es' && platform === 'gibobs') {
		window.open(
			'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/gibobs_all_es.pdf',
			'_blank'
		);
	} else if (lang === 'en' && platform === 'gibobs') {
		window.open(
			'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/gibobs_all_en.pdf',
			'_blank'
		);
	} else if (lang === 'cat' && platform === 'gibobs') {
		window.open(
			'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/gibobs_all_cat.pdf',
			'_blank'
		);
	} else if ((lang === 'es-ES' || lang === 'es') && platform === 'sabadell') {
		window.open(
			'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/brands/sabadell-es/sabadell_all_es.pdf',
			'_blank'
		);
	} else {
		window.open(
			'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/documents/gibobs_all_es.pdf',
			'_blank'
		);
	}
};

export const getTextFromDocumentsDownload = (platform: any, text1: any, text2: any, text3: any) => {
	return (
		<>
			<Row style={{ marginTop: 16 }}>
				<Col>
					<Text style={{ color: '#2F4858', fontSize: isMobileOnly ? 12 : 14, fontWeight: 500 }}>
						<SearchOutlined style={{ marginRight: 6 }} />
						{text1}
					</Text>
				</Col>
			</Row>
			<Row
				style={{ marginLeft: isMobileOnly ? 6 : undefined, marginBottom: isMobileOnly ? 150 : 0 }}>
				<Col className={isMobileOnly ? undefined : 'wrapper-flex'}>
					<div
						className="title-initial-information"
						style={{ color: '#2F4858', fontSize: isMobileOnly ? 10 : 12, fontWeight: 300 }}>
						{text2}
					</div>
					<div
						style={{
							color: '#2F4858',
							fontSize: 12,
							fontWeight: 500,
							marginLeft: 4,
							cursor: 'pointer'
						}}
						onClick={() => getDocumentInfoByDownlands(platform)}>
						{text3}
					</div>
				</Col>
			</Row>
		</>
	);
};
