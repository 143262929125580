import React, { useEffect, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';
import { Form, Input, Select, Typography, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputDate } from 'afrodita';

const { Text } = Typography;
const { Option } = Select;

type Props = {
	setDocSigned: any;
	nameBank: any;
	dataMortgage: any;
	clickTab: any;
	form: any;
};

export default function RenderSingFile({
	setDocSigned,
	nameBank,
	dataMortgage,
	clickTab,
	form
}: Props) {
	const [dataURL, setDataURL] = React.useState<string | null>(null);
	const padRef = React.useRef<SignatureCanvas>(null);
	const { t } = useTranslation();
	const [index, setIndex] = useState(undefined);
	const [initialValues, setInitialValues] = useState(undefined);
	const [participant, setParticipant] = useState(undefined);

	useEffect(() => {
		const index2 = clickTab.charAt(clickTab.length - 1);
		setIndex(index2 - 1);
	}, []);

	useEffect(() => {
		if (dataMortgage && !isNaN(index)) {
			if (clickTab.includes('owner')) {
				setParticipant(dataMortgage?.owners[index]);
			} else {
				setParticipant(dataMortgage?.guarantors[index]);
			}
		}
	}, [index]);

	useEffect(() => {
		form.resetFields();
		if (!isNaN(index) && participant) {
			const updatedValues = {
				name: participant?.name ? participant.name : undefined,
				firstSurname: participant?.firstSurname ? participant.firstSurname : undefined,
				lastSurname: participant?.lastSurname ? participant.lastSurname : undefined,
				email: participant?.email ? participant?.email : undefined,
				nif: participant?.nif ? participant?.nif : undefined,
				phone: participant?.phone ? participant?.phone : undefined,
				address: participant?.address ? participant?.address : undefined,
				population: participant?.population ? participant?.population : undefined,
				postalCode: participant?.postalCode ? participant?.postalCode : undefined,
				birthdate: participant?.birthdate ? participant?.birthdate : undefined
			};
			form.setFieldsValue(updatedValues);
			setInitialValues(updatedValues);
		}
	}, [index, participant]);

	const clear = () => {
		padRef.current?.clear();
	};

	const trim = () => {
		const url = padRef.current?.getTrimmedCanvas().toDataURL('image/png');
		if (url) {
			setDataURL(url);
			setDocSigned(url);
		}
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
			{initialValues && nameBank !== 'consent_caixabank' && nameBank !== 'consent_bankinter' && (
				<Form form={form} initialValues={initialValues} layout="vertical" style={{ width: '100%' }}>
					<Row gutter={[16, 16]} align={'middle'}>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="name"
								label={t('form.name')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.firstSurname}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.name')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="firstSurname"
								label={t('form.firstSurname')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.firstSurname}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.firstSurname')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="lastSurname"
								label={t('form.lastSurname')}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.lastSurname}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.lastSurname')}
								/>
							</Form.Item>
						</Col>
						{nameBank !== 'consent_caja_rural_sur' && (
							<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
								<Form.Item
									className="input-sign"
									name="email"
									label={t('appraisal.emailSecure')}
									rules={[
										{ type: 'email', message: t('form.emailNotValid') },
										{ required: true, message: t('form.required2') }
									]}
									style={{ flex: '1 1 200px' }}>
									<Input
										disabled={!!participant?.email}
										style={{ width: '100%', height: 40 }}
										placeholder={t('appraisal.emailSecure')}
									/>
								</Form.Item>
							</Col>
						)}
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="phone"
								label={t('form.phone')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.phone}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.phone')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="nif"
								label={t('form.identification')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.nif}
									style={{ width: '100%', height: 40 }}
									placeholder={t('0000000X')}
								/>
							</Form.Item>
						</Col>
						{nameBank === 'consent_sabadell_extra' && (
							<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
								<Form.Item
									className="input-sign"
									name="birthdate"
									label={t('form.birthdate')}
									rules={[{ required: true, message: t('form.required2') }]}
									style={{ flex: '1 1 200px' }}>
									<InputDate type="date" style={{ width: '100%', height: 40 }} />
								</Form.Item>
							</Col>
						)}

						{nameBank === 'consent_caja_rural_sur' ||
							(nameBank === 'consent_abanca' && (
								<>
									<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
										<Form.Item
											className="input-sign"
											name="address"
											label={t('form.address')}
											rules={[{ required: true, message: t('form.required2') }]}
											style={{ flex: '1 1 200px' }}>
											<Input
												disabled={!!participant?.address}
												style={{ width: '100%', height: 40 }}
												placeholder={t('form.address')}
											/>
										</Form.Item>
									</Col>
									<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
										<Form.Item
											className="input-sign"
											name="population"
											label={t('form.population')}
											rules={[{ required: true, message: t('form.required2') }]}
											style={{ flex: '1 1 200px' }}>
											<Input
												disabled={!!participant?.population}
												style={{ width: '100%', height: 40 }}
												placeholder={t('form.population')}
											/>
										</Form.Item>
									</Col>
									<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
										<Form.Item
											className="input-sign"
											name="postalCode"
											label={t('m_register.postalCode')}
											rules={[{ required: true, message: t('form.required2') }]}
											style={{ flex: '1 1 200px' }}>
											<Input
												disabled={!!participant?.postalCode}
												style={{ width: '100%', height: 40 }}
												placeholder={t('m_register.postalCode')}
											/>
										</Form.Item>
									</Col>
								</>
							))}
					</Row>
				</Form>
			)}

			{initialValues && nameBank === 'consent_bankinter' && (
				<Form form={form} initialValues={initialValues} layout="vertical" style={{ width: '100%' }}>
					<Row gutter={[16, 16]} align={'middle'} style={{ marginTop: 24 }}>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="name"
								label={t('form.name')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input style={{ width: '100%', height: 40 }} placeholder={t('form.name')} />
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="email"
								label={t('appraisal.emailSecure')}
								rules={[
									{ type: 'email', message: t('form.emailNotValid') },
									{ required: true, message: t('form.required2') }
								]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.email}
									style={{ width: '100%', height: 40 }}
									placeholder={t('appraisal.emailSecure')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8}>
							<Form.Item
								className="input-sign"
								name="phone"
								label={t('form.phone')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.phone}
									style={{ width: '100%', height: 40 }}
									placeholder={t('form.phone')}
								/>
							</Form.Item>
						</Col>
						<Col lg={8} md={12} sm={24} xs={24} xl={8} xxl={8} style={{ marginBottom: 24 }}>
							<Form.Item
								className="input-sign"
								name="nif"
								label={t('form.identification')}
								rules={[{ required: true, message: t('form.required2') }]}
								style={{ flex: '1 1 200px' }}>
								<Input
									disabled={!!participant?.nif}
									style={{ width: '100%', height: 40 }}
									placeholder={t('0000000X')}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			)}

			<div
				style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
				<span style={{ marginTop: 32 }}>Tu firma:</span>
				<SignaturePad
					ref={padRef}
					canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }}
					onEnd={trim}
				/>
				<div className="sigPreview">
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: 16,
							width: 120,
							background: '#02C3CD',
							color: '#ffffff',
							border: 'none',
							padding: 4,
							borderRadius: 4
						}}
						onClick={clear}>
						Borrar
					</button>
				</div>
			</div>
		</div>
	);
}
